import React from "react";
import config from "../../config";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import FlipCard from "../components/FlipCard";
import Layout from "../components/Layout";
import Header from "../components/Header";
import BookVisit from "../components/BookVisit";
import Footer from "../components/Footer";
import Reviews from "../components/Reviews";
import SocialLinks from "../components/SocialLinks";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  
  faBrain,
  faBullhorn,
  faSeedling,
  faWallet,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faLightbulb,
  faHourglass
} from "@fortawesome/free-regular-svg-icons";



const shareButtonsIcons = [
  faLightbulb,
  faBrain,
  faBullhorn,
  faSeedling,
  faWallet,
  faClock,
  faHourglass,
  faKey,
];

library.add(...shareButtonsIcons);
const IndexPage = ({ data }) => (
  <Layout
    title={"Sobre a EMMIM®"}
    description="Porque somos o seu braço direito no seu dia a dia?Somos mais que um espaço..."
  >
    <Header
      className="about"
      smallSubtitle="Somos mais que um espaço..."
      title={
        <>
          <h1>Porque somos o seu braço direito no seu dia a dia?</h1>
        </>
      }
      active="sobre"
    />

    <div className="bg-fg-withLogo noGutters">
      <div className="container-fluid">
        <div className="row p-5 justify-content-md-center text-center">
          <div className="col col-sm-12 p-3">
            <h1 className="text-primary">OS PRINCIPIOS QUE NOS DEFINEM</h1>
            <div className="container-fluid">
              <div className="row pt-5 justify-content-md-center text-center">
                <Zoom>
                  <div className="col col-sm-12 col-md-3 text-center p-3">
                    <FontAwesomeIcon
                      icon={["far", "lightbulb"]}
                      className="iconBlock"
                    />
                    <p>Pensamento Inovador</p>
                  </div>
                </Zoom>
                <Zoom>
                  <div className="col col-sm-12 col-md-3 p-3">
                    <FontAwesomeIcon icon={"brain"} className="iconBlock" />
                    <p>Encontrar soluções Criativas</p>
                  </div>
                </Zoom>
                <Zoom>
                  <div className="col col-sm-12 col-md-3 p-3">
                    <FontAwesomeIcon icon={"bullhorn"} className="iconBlock" />
                    <p>Mentalidade Colaborativa</p>
                  </div>
                </Zoom>
                <Zoom>
                  <div className="col col-sm-12 col-md-3 p-3">
                    <FontAwesomeIcon icon={"seedling"} className="iconBlock" />
                    <p>EVOLUÇÃO CONSTANTE</p>
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center text-center">
          <div className="col col-sm-12 p-3">
            <h1 className="text-primary">
              Total Liberdade, total Responsabilidade
            </h1>
            <p className="p-3">
              Total liberdade para decidir o seu modelo de negócio
            </p>
            <div className="container-fluid">
              <div className="row justify-content-md-center text-center">
                <Zoom>
                  <div className="col col-sm-12 col-md-3 text-center p-3">
                    <FontAwesomeIcon icon={"wallet"} className="iconBlock" />
                    <p>Preço das consultas</p>
                  </div>
                </Zoom>
                <Zoom>
                  <div className="col col-sm-12 col-md-3 p-3">
                    <FontAwesomeIcon
                      icon={["far", "hourglass"]}
                      className="iconBlock"
                    />
                    <p>Duração das consultas</p>
                  </div>
                </Zoom>
                <Zoom>
                  <div className="col col-sm-12 col-md-3 p-3">
                    <FontAwesomeIcon
                      icon={["far", "clock"]}
                      className="iconBlock"
                    />
                    <p>Horário</p>
                  </div>
                </Zoom>
                <Zoom>
                  <div className="col col-sm-12 col-md-3 p-3">
                    <FontAwesomeIcon icon={"key"} className="iconBlock" />
                    <p>acesso por código</p>
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center text-center">
          <div className="col col-sm-12 p-3">
            <h3 className="p-3 text-color">
              O nosso intuito é reunir profissionais de várias valências num
              único espaço e providênciar o apoio e o espaço perfeito para que
              possam exercer com qualidade numa zona premium.
            </h3>
            <h3 className="p-3 text-color">
              É um espaço onde as pessoas vêm tratar de si, seja qual for a
              área.
            </h3>
            <h3 className="p-3 text-color">
              No Mundo atribulado em que vivemos, cada vez são menos os momentos
              que tiramos para nós próprios.
            </h3>
          </div>
        </div>
      </div>
    </div>

    <Fade bottom>
      <div className="container-fluid">
        <div className="row p-5 justify-content-md-center text-center">
          <div className="col col-sm-12">
            <h3 className="p-3">O nosso horário</h3>
            <h1 className="p-5 text-primary big">365 dias por ano</h1>
            <p className="">Das 8h às 23h todos os dias </p>
            <p className="">
              Recepcionista nos dias úteis (ou mediante pedido)
            </p>
          </div>
        </div>
      </div>
    </Fade>
    <div className="container-fluid content gray-100-bg  noGutters">
      <Fade bottom distance="100px">
        <div className="row p-5 justify-content-md-center text-center">
          <div className="col col-sm-12">
            <h1 className="p-5 text-primary big">
              Quem são os nossos clientes?
            </h1>
          </div>
        </div>
      </Fade>
      <Fade left distance="100px">
        <div className="row justify-content-md-center text-center no-gutters">
          <div className="col-sm-12 col-md-6 order-md-2">
            <Img
              alt="EMMIM® - Aluger consultórios privados para coach's e Psicólogos"
              className={"img-fluid"}
              fluid={data.coach.childImageSharp.fluid}
            />
          </div>
          <div className="col-sm-12 col-md-6 order-md-1 ">
            <h1>COACH'S E PSICÓLOGOS</h1>
            <p className="p-5">
              Pessoas que dedicam a sua vida ao desenvolvimento pessoal
            </p>
          </div>
        </div>
      </Fade>
      <Fade right distance="100px">
        <div className="row justify-content-md-center text-center no-gutters">
          <div className="col-sm-12 col-md-6 order-md-1">
            <Img
              className={"img-fluid"}
              alt="EMMIM® - Aluger consultórios privados para terapeutas de Acupuntura, Homeopatia, Naturopatia, Osteopatia todas as áreas
              chamadas não convecionais"
              fluid={data.terap.childImageSharp.fluid}
            />
          </div>
          <div className="col-sm-12 col-md-6 order-md-2">
            <h1 className="p-5">TERAPEUTAS</h1>
            <p className="p-5">
              Acupuntura, Homeopatia, Naturopatia, Osteopatia todas as áreas
              chamadas não convecionais
            </p>
          </div>
        </div>
      </Fade>
      <Fade left distance="100px">
        <div className="row justify-content-md-center text-center no-gutters">
          <div className="col-sm-12 col-md-6 order-md-2">
            <Img
              alt="EMMIM® - Aluger consultórios privados para médicos"
              className={"img-fluid"}
              fluid={data.doctor.childImageSharp.fluid}
            />
          </div>
          <div className="col-sm-12 col-md-6 order-md-1 ">
            <h1 className="pt-5">MÉDICOS e outros profissionais de saúde</h1>
            <p className="p-5">
              Clinica geral ou de especialidade, nutricionistas, terapeutas da
              fala, entre outras{" "}
            </p>
          </div>
        </div>
      </Fade>
      <Fade right distance="100px">
        <div className="row justify-content-md-center text-center no-gutters">
          <div className="col-sm-12 col-md-6 order-md-1 ">
            <Img
              className={"img-fluid"}
              alt="EMMIM® - Aluger consultórios privados para Terapeutas de energias"
              fluid={data.tarot.childImageSharp.fluid}
            />
          </div>
          <div className="col-sm-12 col-md-6 order-md-2">
            <h1 className="p-5">TERAPEUTAS DE ENERGIAS</h1>
            <p>Reiki, Numerologia, Astrologia, Tarot, entre outras</p>
          </div>
        </div>
      </Fade>
     
    </div>
    <Fade bottom>
      <div className="container-fluid">
        <div className="row justify-content-md-center no-gutters pt-5">
          <Reviews />
        </div>
      </div>
    </Fade>
    <Fade bottom>
      <div className="container-fluid gray-100-bg ">
        <div className="row p-5 justify-content-md-center text-center">
          <div className="col col-sm-12">
            <h1 className="p-5 text-primary">Conheça a nossa equipa</h1>
            <h2 className="text-color">
              "O nosso compromisso é 101% de dedicação"
            </h2>
          </div>
        </div>
        <div className="row p-5 justify-content-md-center text-center">
          <FlipCard
            title="Joana Loureiro"
            frontText="Directora"
            image={data.joana}
            nrCols={4}
          />
          <FlipCard
            title="Bruno Loureiro"
            frontText="Co-founder"
            image={data.bruno}
            nrCols={4}
          />
          
          <FlipCard
            title="Liza"
            frontText="Especialista na arte de bem receber"
            image={data.logo}
            nrCols={4}
          />
          <FlipCard
            title="Joana"
            frontText="Especialista na arte de bem receber"
            image={data.logo}
            nrCols={4}
          />
          <FlipCard
            title="Rita"
            frontText="Especialista na arte de bem receber"
            image={data.logo}
            nrCols={4}
          />
        </div>
      </div>
    </Fade>
    <BookVisit title="Vamos conhecer-nos?" />
    <SocialLinks />
    <Footer />
  </Layout>
);
export const AboutImages = graphql`
         query AboutImages {
           tarot: file(relativePath: { eq: "tarot.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }

           joanaTop: file(relativePath: { eq: "joana.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           joana: file(relativePath: { eq: "joanaBottom.jpg" }) {
             childImageSharp {
               fixed(width: 128, height: 128) {
                 ...GatsbyImageSharpFixed_withWebp
               }
             }
           }
           bruno: file(relativePath: { eq: "bruno.jpg" }) {
             childImageSharp {
               fixed(width: 128, height: 128) {
                 ...GatsbyImageSharpFixed_withWebp
               }
             }
           }
          

           logo: file(relativePath: { eq: "logo.png" }) {
             childImageSharp {
               fixed(width: 128, height: 128) {
                 ...GatsbyImageSharpFixed_withWebp
               }
             }
           }

           trainning: file(relativePath: { eq: "trainning.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }

           coach: file(relativePath: { eq: "coach.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }

           doctor: file(relativePath: { eq: "doctor.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }

           terap: file(relativePath: { eq: "terap.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
         }
       `;

export default IndexPage;
